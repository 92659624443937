
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import { ParsedUrlQuery } from 'querystring';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import { NextPageWithLayout } from 'types/app';
import { HeadTemplate } from '~/components/functional/Head';
import { LayoutBase, Props as LayoutBaseProps } from '~/components/functional/LayoutBase';
import { LoginLayout } from '~/components/layout/LoginLayout';
import { Login } from '~/components/page/Login';
import { LOGIN_TYPE } from '~/constants/LoginType';
import { GetServerSidePropsWithError, withErrorHandling } from '~/utils/withErrorHandling';
import { withRollbarConfig } from '~/utils/withRollbarConfig';

type PageProps = LayoutBaseProps;

interface Params extends ParsedUrlQuery {
  organization: string;
  portal: string;
}

 const _getServerSideProps: GetServerSidePropsWithError<PageProps, Params> = withErrorHandling(async () => {
  return {
    props: withRollbarConfig({}),
  };
});

const Page: NextPageWithLayout<PageProps> = ({}) => {
  const { t } = useTranslation();
  return (
    <>
      <HeadTemplate pageTitle={t('login:title')} pageName={t('component.header.portalMenu.openBrain')} isNoIndex />
      <Login loginType={LOGIN_TYPE.OPEN_BRAIN} />
    </>
  );
};

Page.getLayout = (component, { rollbarConfig }) => (
  <LayoutBase rollbarConfig={rollbarConfig}>
    <LoginLayout>{component}</LoginLayout>
  </LayoutBase>
);

export default Page;


// @ts-ignore
    export async function getServerSideProps(ctx) {
// @ts-ignore
        let res = _getServerSideProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/login/index',
// @ts-ignore
              loaderName: 'getServerSideProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  